<template>
  <div class="bg global-blue">
    <v-container>
      <v-row>
        <v-col>
          <h1 class="global-title-sm hidden-md-and-up mt-12">{{ translations.title[locale] }}</h1>
          <h1 class="global-title hidden-sm-and-down">{{ translations.title[locale] }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="white--text ma-4">{{ translations.trlbasic[locale] }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <h1 class="ma-4 global-title-sm hidden-md-and-up">{{ translations.trlEvalTitle[locale] }}</h1>
            <h1 class="ma-4 global-title hidden-sm-and-down">{{ translations.trlEvalTitle[locale] }}</h1>
          </v-row>
          <v-row>
            <p class="white--text ma-4">{{ translations.trlEvalSubtitle[locale] }}</p>
          </v-row>
          <v-row>
            <v-card
              dark
              flat
              outlined
              class="br ma-4"
              min-width="300"
            >
              <v-card-title class="hidden-md-and-up subtitle-1">{{ translations.devTrlEvalTitle[locale] }}</v-card-title>
              <v-card-title class="hidden-sm-and-down title font-weight-bold">{{ translations.devTrlEvalTitle[locale] }}</v-card-title>

              <v-divider
                inset
                class="ma-2"
              ></v-divider>
              <v-card-text
                class="white--text mb-n6 ml-4"
                v-for="(t,i) in translations.devTrlEvalBasic[locale]"
                :key="`b${i}`"
              >{{ t }}</v-card-text>
              <v-divider
                inset
                class="ma-2 mt-6"
              ></v-divider>
              <v-card-subtitle class="pb-0 my-4">{{ translations.devTrlEvalTechTitle[locale] }}</v-card-subtitle>
              <v-card-text
                class="white--text mb-n6 ml-4"
                v-for="(t,i) in translations.devTrlEvalTechContent[locale]"
                :key="`c${i}`"
              >{{ t }}</v-card-text>
              <v-card-text class="white--text font-weight-bold mb-n6 ml-4">{{ translations.devTrlEvalTechEmp[locale][0] }}</v-card-text>
              <v-card-text class="white--text mb-n6 ml-12">{{ translations.devTrlEvalTechEmp[locale][1] }}</v-card-text>
              <v-divider
                inset
                class="ma-2 mt-6"
              ></v-divider>
              <v-card-subtitle class="pb-0 my-4">{{ translations.devTrlEvalReadinessTitle[locale] }}</v-card-subtitle>
              <v-card-text
                class="white--text mb-n6 ml-4"
                v-for="(t,i) in translations.devTrlEvalReadinessContent[locale]"
                :key="`d${i}`"
              >{{ t }}</v-card-text>
              <v-divider
                inset
                class="ma-2 mt-6"
              ></v-divider>
              <v-card-subtitle class="pb-0 my-4">{{ translations.devTrlEvalLevelTitle[locale] }}</v-card-subtitle>
              <v-card-text class="white--text font-weight-medium mb-n6 ml-4">{{ translations.devTrlEvalLevelContent[locale][0] }}</v-card-text>
              <v-card-text class="white--text font-weight-medium mb-n6 ml-4">{{ translations.devTrlEvalLevelContent[locale][1] }}</v-card-text>
              <v-card-text class="white--text font-weight-medium mb-n6 ml-4">{{ translations.devTrlEvalLevelContent[locale][2] }}</v-card-text>
              <v-card-text class="white--text font-weight-medium mb-n6 ml-4">{{ translations.devTrlEvalLevelContent[locale][3] }}</v-card-text>
              <v-card-text></v-card-text>
            </v-card>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="white--text ma-4">{{ translations.trlSummary[locale][0] }}</p>
              <p class="white--text ma-4">{{ translations.trlSummary[locale][1] }}</p>
              <p class="white--text ma-4">{{ translations.trlSummary[locale][2] }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <h2 class="title-info ma-4">{{ translations.infotitle[locale] }}</h2>
            </v-col>
          </v-row>
          <!-- Info -->
          <v-card
            dark
            flat
            outlined
            class="br ma-4 pa-4 my-12 hidden-sm-and-down"
            min-width="400"
          >
            <v-list
              disabled
              dense
              rounded
              dark
              class="back"
            >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, infoindex) in translations.info"
                  :key="`info_${infoindex}`"
                >
                  <v-list-item-avatar
                    class="my-1"
                    :color="item.color"
                    size="48"
                  >
                    <span class="white--text headline">{{ item.id }}</span>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item[locale]"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <!-- irl -->
          <v-row>
            <v-col>
              <h2 class="title-info ma-4 hidden-sm-and-down">{{ translations.irltitle[locale] }}</h2>
            </v-col>
          </v-row>
          <v-row class="hidden-sm-and-down">
            <v-col>
              <template v-for="(text, indexIrl) in translations.irlText">
                <div :key="`a__${indexIrl}`">
                  <p class="white--text ma-4">{{ text[locale] }}</p>
                </div>
              </template>
              <template v-for="(irl, indexIrl2) in translations.irlExplanation">
                <div :key="`b__${indexIrl2}`">
                  <p class="white--text font-weight-bold ma-4">{{irl[locale + 'b']}}</p>
                  <p class="white--text ma-6">{{ irl[locale] }}</p>
                </div>
              </template>
            </v-col>
            <v-col>

              <v-card
                dark
                flat
                outlined
                class="br ma-4 pa-4 hidden-sm-and-down"
                min-width="400"
              >
                <v-list
                  disabled
                  dense
                  rounded
                  dark
                  class="back"
                >
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, irlindex) in translations.irl"
                      :key="`info_${irlindex}`"
                    >
                      <v-list-item-avatar
                        class="my-1"
                        :color="item.color"
                        size="48"
                      >
                        <span class="white--text headline">{{ item.id }}</span>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="item[locale]"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

              </v-card>
            </v-col>
          </v-row>
          <v-card
            dark
            flat
            outlined
            class="br ma-4 small-card hidden-md-and-up"
            min-width="300"
          >
            <v-list
              disabled
              dense
              rounded
              dark
              class="back"
            >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, infoindex) in translations.info"
                  :key="`info_${infoindex}`"
                >
                  <v-list-item-avatar
                    class="my-n1 ml-n12"
                    :color="item.color"
                    size="34"
                  >
                    <span class="white--text headline">{{ item.id }}</span>
                  </v-list-item-avatar>
                  <v-list-item-content class="ml-n2 my-2">
                    <p class="trl-case-sm my-0">
                      {{item[locale]}}
                    </p>
                    <!-- <v-list-item-title v-text="item[locale]"></v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-row>
            <v-col>
              <h2 class="title-info ma-4 hidden-md-and-up">{{ translations.irltitle[locale] }}</h2>
            </v-col>
          </v-row>
          <v-row class="hidden-md-and-up">
            <v-col>

              <template v-for="(text, indexIrl) in translations.irlText">
                <div :key="`a_${indexIrl}`">
                  <p class="white--text ma-4">{{ text[locale] }}</p>
                </div>
              </template>
              <template v-for="(irl, indexIrl2) in translations.irlExplanation">
                <div :key="`b_${indexIrl2}`">
                  <p class="white--text font-weight-bold ma-4">{{irl[locale + 'b']}}</p>
                  <p class="white--text ma-6">{{ irl[locale] }}</p>
                </div>
              </template>
            </v-col>
          </v-row>
          <v-card
            dark
            flat
            outlined
            class="br ma-4 small-card hidden-md-and-up"
            min-width="300"
          >

            <v-list
              disabled
              dense
              rounded
              dark
              class="back"
            >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, irlindex) in translations.irl"
                  :key="`info_${irlindex}`"
                >
                  <v-list-item-avatar
                    class="my-n1 ml-n12"
                    :color="item.color"
                    size="34"
                  >
                    <span class="white--text headline">{{ item.id }}</span>
                  </v-list-item-avatar>
                  <v-list-item-content class="ml-n2 my-2">
                    <p class="trl-case-sm my-0">
                      {{item[locale]}}
                    </p>
                    <!-- <v-list-item-title v-text="item[locale]"></v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="bg-sm">

    </div>
  </div>
</template>

<style  scoped>
.bg-sm {
  height: 140px;
  width: 100%;
}
.bg {
  height: 100%;
}
.br {
  background: var(--v-primary-base) !important;
}
.trl-case-sm {
  font-size: 12px;
}
.back {
  background: var(--v-primary-base) !important;
  padding: 32px;
  margin: 16px;
  width: 100%;
}

h2 {
  font-weight: 400;
}
h1 {
  text-align: left;
}
.title-info {
  color: #f7941e;
}
</style>

<script>
import { PORTAL_INFO } from "../../constants/translations";

export default {
  name: "Info",
  data() {
    return {
      translations: PORTAL_INFO,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
  },
};
</script>